.header {
  position: absolute sticky;
  top: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 0.1px solid lightgray; */
  background-color: white;
  width: 100%;
  z-index: 999;
}

.header__left {
  display: flex;
}

.header__title {
  display: flex;
  justify-content: flex-start;
  object-fit: contain;
  height: 40px;
  margin-left: 20px;
}

.header__title > a > img {
  object-fit: contain;
  height: 10px;
  margin-left: 40px;
}

.header__left > img {
  object-fit: contain;
  height: 40px;
  margin-left: 40px;
}

.header__right {
  display: flex;
}
