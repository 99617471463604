/* .app { */
/* position: absolute sticky;
  top: 0;
  padding: 10px; */
/* display: flex;
  justify-content: center; */
/* border-bottom: 0.1px solid lightgray;
  background-color: white; */
/* width: 100%;
  z-index: 999;
  align-items: center;
  height: 100vh; */
/* } */

.content {
  /* height: calc(100vh-64px); */
  padding-bottom: 60px;
}
.app {
  text-align: center;
  background-color: #f8f8fa;
  position: relative;
  min-height: 100vh;
}

.footer {
  position: absolute;
  bottom: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2.5rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.profile-img-container {
  position: absolute;
  overflow:hidden;
}

.profile-img-container img:hover {
  opacity: 0.5;
}

.profile-img-container img + span {
  display: none;
}
.profile-img-container img:hover + span {
  display: block;
}


.profile-img-container .icon-wrapper {
  position: absolute;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.7);
  color:#fff;
  text-align:center;
  width:100%;
  padding:5px;
}
