.navOptions {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  /* margin-right: 20px; */
  padding-right: 40px;
  color: black;
  cursor: pointer;
  align-items: center;
}

.navOptions__title:hover {
  color: darkgrey;
}

.navOptions__title {
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 10px;
}
